.defaultMessage {
  width: 600px;
  border-radius: 8px;
  margin: 20px auto;
  padding: 8px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  .messageWrapper {
    display: flex;
    align-items: center;

    &.success {
      color: #0c501e;
    }

    &.warn {
      color: #af5e08;
    }

    &.info {
      color: #007089;
    }

    &.error {
      color: #870000;
    }
    .message {
      margin: 0px 10px;
      color: white;
      letter-spacing: 2px;
      font-weight: 400;
    }
  }

  &.success {
    color: #039342;
    background-color: rgb(32 149 47 / 81%);
    border: 1px solid #01633b;
  }

  &.warn {
    color: #af5e08;
    background-color: rgba(175, 94, 8, .4);
    border: 1px solid #5b351a;
  }

  &.info {
    color: #007089;
    background-color: rgba(0, 112, 137, .4);
    border: 1px solid #00486c;
  }

  &.error {
    color: #a33135;
    background-color: #e15b68c7;
    border: 1px solid #772636;
  }

  .close {
    cursor: pointer;
    color: white;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      color: #ff6473;
    }
  }

}

@media only screen and (max-width: 640px) {
  .defaultMessage {
    width: auto;
  }
}
