@import "styles/colors.module.scss";

html {
  height: 100%;
}

#__next {
  height: 100%;
}

body {
  font-family: Antonio, sans-serif;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100%;
  //background-color: rgb(14, 20, 32);
  //background: linear-gradient(95.83deg, #B4D4FF 4.09%, rgb(187 182 182) 64.5%),#fff;
  background: linear-gradient(95.83deg, #B4D4FF 4.09%, #e3e3e3 64.5%),#fff;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

svg {
  font-size: 22px;
}

#Callout {
  margin-top: 35px;
  color: #8d8d8d;
  font-weight: 200;
  text-align: center;

  a {
    color: #3fadff;
    margin-left: 5px;
    transition: 1s;
    &:hover {
      transition: 1s;
      color: #03e9f4;
    }
  }
}

.notice-list-container {
  position: absolute;
  z-index: 999;
  width: 100%;
  top: 0;
  left: 0;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 9;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid $orange900;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
