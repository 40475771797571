$gray-200: #f5f5f7;
$gray-400: #6a6a6a;
$gradient1: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,94,121,1) 35%, rgba(118,136,205,1) 100%, rgba(0,212,255,1) 100%);
$whiteOpacity-200: #ebebebc7;
$black: black;
$black900: #262525;
$lilac: #03e9f4;
$white: #fff;
$orange: #f48803;
$orange900: #ed6f00;

:export {
  whiteOpacity: $whiteOpacity-200;
}
